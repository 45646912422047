import React from 'react';

import LandingPageDesignDeviceFocus2023 from './design_device_focus_2023';

// LandingPageDesignMedicareAdvantage2024 is functionaly identical to LandingPageDesignDeviceFocus2023 but with different copy
function LandingPageDesignMedicareAdvantage2024() {
  return <LandingPageDesignDeviceFocus2023 />;
}

export default LandingPageDesignMedicareAdvantage2024;
