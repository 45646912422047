import React from 'react';

import CtaButton from '../cta_button';

function EligibilityBanner2021({ has_nonfree_program }) {
  const headerCtaCopy = window.dataFromRuby.eligibility_banner;

  const copy_to_use = has_nonfree_program ? headerCtaCopy.nonfree : headerCtaCopy.default;

  return (
    <div className='eligibility-banner-2021'>
      <div className='eligibility-banner__content'>
        <p className='ev-header-base--xs eligibility-banner-2021__main-text ev-color-white qa-eligibility-banner-2021-headline'>
          {copy_to_use.headline}
        </p>
        <CtaButton
          classNames='ev-button-white u-4pl--xl u-4pr--xl qa-eligibility-banner-2021-cta'
          ctaText={copy_to_use.cta}
          section='eligibility-banner'
        />
      </div>
    </div>
  );
}

export default EligibilityBanner2021;
