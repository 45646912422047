import React, { useEffect } from 'react';

import LandingPageDesign2021 from './designs/design_2021';
import LandingPageDesignDeviceFocus2023 from './designs/design_device_focus_2023';
import LandingPageDesignMedicareAdvantage2024 from './designs/design_medicare_advantage_2024';
import LandingPageDesignWithTestimonials2023 from './designs/design_with_testimonials_2023';
import visitorEventCallback from './utils/visitor_event_callback';

import VisitorEvent from 'models/visitor_event';

function LandingPageContainer() {
  const { attribution_session_id, design_type, force_landing_page_design, page_url } = window.dataFromRuby;
  const landing_page_design = force_landing_page_design || design_type;
  const metadata = { page_url };

  useEffect(() => {
    mixpanel.track('landed on page', {
      page: window.location.pathname.replace('/', '') === '' ? 'home' : window.location.pathname.replace('/', ''),
      design_version: landing_page_design,
    });

    const selector = 'a.cta-trigger'; // referenced set in CtaButton component
    mixpanel.track_links(selector, 'click_landing_page_cta', visitorEventCallback);

    VisitorEvent.create(
      {
        attribution_session_id,
        visitor_event: { event: 'page loaded', metadata },
      },
      (err, response) => {},
    );
  }, []);

  switch (landing_page_design) {
    case 'design_medicare_advantage_2024':
      return <LandingPageDesignMedicareAdvantage2024 />;
    case 'design_with_testimonials_2023':
      return <LandingPageDesignWithTestimonials2023 />;
    case 'design_device_focus_2023':
      return <LandingPageDesignDeviceFocus2023 />;
    case 'design_2021':
      return <LandingPageDesign2021 />;
    default:
      return <LandingPageDesign2021 />;
  }
}

export default LandingPageContainer;
